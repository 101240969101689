import React from 'react'
import { Container } from 'react-bootstrap'
import { HeaderDashboard } from '../../componentes/dashboards/HeaderDashboard'
import StyleGenerarCertificado from '../../assets/css/screens/dashboards/StyleGenerarCertificado.css'

export const GenerarCertificado = () => {

  return (
    <div>

    </div>
  )
}
