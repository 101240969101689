import React, { useState, useEffect } from "react";
import { Header } from "../componentes/Header";
import { Banner } from "../componentes/Banner";
import { homeCarousel01, homeCarousel02, homeCarousel03, homeCarousel04 } from "../assets/img";
import { CarouselCourses } from "../componentes/CarouselCourses";
import { CarouselEnterprises } from "../componentes/CarouselEnterprises";
import { Mockups } from "../componentes/Mockups";
import { Footer } from "../componentes/Footer";
import { PreguntasFrecuentes } from "../componentes/PreguntasFrecuentes";
import { getCourse } from "../services/services";
import { Container } from "react-bootstrap";
import { HeaderDashboard } from "../componentes/dashboards/HeaderDashboard";

export const InicioScreen = () => {

  const [data, setData] = useState([]);
  const home = true;
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    async function getCarrouselCourses() {
      const data = await getCourse()
      console.log(data.response._embedded.courses)
      setData(data.response._embedded.courses)
    }
    getCarrouselCourses()
  }, [])


  return (
    <div className="inicioScreen">

      <div className="Inicio banner&header">
        {/* <Header /> */}
        {/* <HeaderDashboard home={home} /> */}
        <Header home={home} />
        <Banner />
      </div>

      <div className="carousel-section carousel-home">
        <div className="carousel__section-content">
          <h1>Elige los cursos de tu interés</h1>

           <ul className="carousel__lista">
            <button>Todas las áreas</button> |
            <button>Innovación</button> |
            <button>Corporativo</button> |
            <button>Transformación</button> |
            <button>Diseño de productos</button> |
            <button>Sostenibilidad</button>
          </ul> 

          <div className="d-flex carousel__container carousel-interna-home" style={{padding:"0",    margin: "0"}}>
            <Container>
              <CarouselCourses item={data} />
            </Container>
          </div>
        </div>
      </div>

      <div className="enterprise-section">
        <CarouselEnterprises />
      </div>

      <div className="mockups-section">
        <Mockups />
      </div>

      <div className="preguntas-section">
        <PreguntasFrecuentes />
      </div>

      <div className="footer-section">
        <Footer />
      </div>

    </div>
  );
};
