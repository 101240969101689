
export const types = {
    /* AUTH */
    login: '[AUTH] LOGIN',
    logout: '[AUTH] LOGOUT',
    register: '[AUTH] REGISTER',

    /* Diagnostico */
    proyectoRoute: '[ROUTE] PROYECTO',
    entrenamientoRoute: '[ROUTE] ENTRENAMIENTO',
    ruta: '[ROUTE] CONFIRMACION',
    clearRoute: '[ROUTE] CLEAR',

    /* Evaluacion */
    evaluation: '[EVALUATION] EVALUATION',

    /* Sizes de Navegacion */
    resize: '[SIZE] SIZE',

    /* Unlock Courses */
    unlock: '[UNLOCK] UNLOCKED'
}